import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Footer from '../components/Footer'
import Headline from '../components/Headline'
import IntroGradient from '../components/IntroGradient'
import LogoFixed from '../components/LogoFixed'
import Menu from '../components/Menu'
import BrandTile from '../components/BrandTile'
import Seo from '../components/Seo'

const Brands = () => {
  const { brands, count } = useStaticQuery(graphql`
    {
      brands: allContentfulBrand(sort: { fields: date, order: ASC }) {
        nodes {
          slug
          title
          seo_title
          image {
            gatsbyImageData(
              quality: 100
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.33
            )
          }
          logo {
            file {
              url
            }
          }
          website
          desc
          desc2 {
            childMarkdownRemark {
              html
            }
          }
          role
        }
      }
      count: allContentfulBrand {
        nodes {
          slug
        }
      }
    }
  `)

  return (
    <>
      <Seo 
        title='Poznaj Moje Marki'
        desc='' 
        url='/tworca-marek/' 
      />
      <Menu />
      <LogoFixed />
      <IntroGradient />
      <Headline back='Marki'>
        <h1>
          TWÓRCA MAREK  
        </h1>
      </Headline>
      {brands?.nodes?.map((brand, id) => (
        <BrandTile data={brand} key={id} />
      ))}
      <Footer />
    </>
  )
}

export default Brands
